import request from "@/utils/request";

/**
 * 提交投票
 * @param voteIds
 * @param code
 * @returns {AxiosPromise}
 */
 export function publishVote(data) {
  return request({
      url: '/api/zeekr-article/votedetails/save',
      method: 'post',
      data
  })
}