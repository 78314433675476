<template>
  <div class="activity">
    <pageHead title="社区活动"/>
    <div class="activity_content" v-infinite-scroll="loadMore">
      <topic v-for="(item,index) in topicData" :key="item.id" :data="item"
              class="topicItem"
              @comment="handleComment"
              @share="handleShare"
              @like="like($event, index)"
              @favorite="favorite($event, index)"
              @originalClick="handleTopicClick"
              @click="handleTopicClick"
              @checkVote='handleChooseVote($event, item, index)'
              @vote='handlePublicVote(item)'/>
      <no-more v-if="!listQueryParams.hasNextPage"/>
    </div>
    <commentToolDialog :show-dialog.sync="showDialog" :isAnonymousComment.sync="isAnonymousComment"
                         v-model="commentContent" hide-relation hide-img
                         @publish="handlePublishComment"/>
  </div>
</template>

<script>
import pageHead from '@/components/pageHead'
import topic from '@/components/topicItem'
import NoMore from '@/components/uitls/NoMore'
import commentToolDialog from '@/components/commentToolDialog'

import {getByPage} from "@/api/article";
import {addThumbsup, removeThumbsup} from "@/api/thumbsup";
import {addFavorites, removeFavorites} from "@/api/favorites";
import {addComment} from "@/api/comment";
import {publishVote} from '@/api/votedetails'

const ENTITY_TYPE_ARTICLE = 1

export default {
  components: {
    pageHead,
    topic,
    NoMore,
    commentToolDialog
  },
  data() {
    return {
      topicData: [],
      listQueryParams: {
        pageNum: 1,
        pageSize: 10,
        hasNextPage: true,
        isVote: 1
      },
      showDialog: false,
      isAnonymousComment: false,
      commentContent: "",
      voteChooseList: []
    }
  },
  methods: {
    loadMore() {
      this.loadList();
    },
    loadList() {
      let listQueryParams = this.listQueryParams;
      if (listQueryParams.hasNextPage) {
        getByPage(this.listQueryParams).then(res => {
          listQueryParams.hasNextPage = res.current < res.pages
          listQueryParams.pageNum = listQueryParams.pageNum + 1
          let resData = this.topicData;
          res.records.forEach(item => {
            let count = resData.filter(innerItem => innerItem.id === item.id)
            item.voteChooseList = []
            item.superAdmin = 2
            if (count.length === 0) {
              resData.push(item)
            }
          })
          this.topicData = resData
        })
      }
    },
    /**
     * 添加评论
     * @param item
     */
    handleComment(item) {
      this.showDialog = true
      this.commentParams = item
    },
    /**
     * 分享事件
     */
    handleShare({id: quoteId} = {}) {
      this.$router.push({name: 'createTopic', query: {quoteId}})
    },
    like(res, index) {
      // 取消点赞
      if (res.thumbsUpFlag) {
        removeThumbsup({
          entityId: res.id,
          entityType: 1
          // eslint-disable-next-line no-unused-vars
        }).then(() => {
          let data = this.topicData[index];
          this.topicData[index].thumbsUpFlag = !res.thumbsUpFlag
          let count = data.relationVO.thumbsUpCount - 1;
          if (count < 0) count = 0;
          data.relationVO.thumbsUpCount = count
        })
      } else {
        // 添加点赞
        addThumbsup({
          entityId: res.id,
          entityType: 1
        }).then(() => {
          let data = this.topicData[index];
          this.topicData[index].thumbsUpFlag = !res.thumbsUpFlag
          if (!data.relationVO) {
            data.relationVO = {}
          }
          data.relationVO.thumbsUpCount = data.relationVO.thumbsUpCount + 1
        })
      }
    },

    favorite(res, index) {
      // 取消点赞
      if (res.favoritesFlag) {
        removeFavorites([res.id]).then(() => {
          let data = this.topicData[index];
          this.topicData[index].favoritesFlag = !res.favoritesFlag
          let count = data.relationVO.favoriteCount - 1;
          if (count < 0) count = 0;
          data.relationVO.favoriteCount = count
        })
      } else {
        // 添加点赞
        addFavorites({
          articleId: res.id,
        }).then(() => {
          let data = this.topicData[index];
          this.topicData[index].favoritesFlag = !res.favoritesFlag
          if (!data.relationVO) {
            data.relationVO = {}
          }
          data.relationVO.favoriteCount = data.relationVO.favoriteCount + 1
        })
      }
    },
    handleTopicClick({id} = {}) {
      this.$router.push({name: 'topicDetail', query: {id}})
    },
    /**
     * 发布评论
     */
    async handlePublishComment() {
      const {commentContent: content, commentParams: {id: entityId}, isAnonymousComment} = this
      const entityType = ENTITY_TYPE_ARTICLE
      if (!content.trim()) {
        this.$notify({message: '评论内容不能为空', type: 'danger'});
        return
      }
      const anonymous = isAnonymousComment ? 1 : 0
      await addComment({content, entityId, entityType, anonymous})
      this.showDialog = false
      this.commentParams = {}
      this.commentContent = ""
      this.topicData = this.topicData.map(item => {
        if (item.id !== entityId) {
          return item
        }
        const {relationVO} = item
        return {
          ...item,
          relationVO: {...relationVO, commentCount: relationVO.commentCount + 1}
        }
      })
    },
    handleChooseVote(voteItem, topicItem, index) {
      const {pattern} = topicItem
      let {voteChooseList} = this.topicData[index]
      if(pattern === 0) {
        topicItem.voteList.forEach(item => {
          if(item.id === voteItem.id) {
            item.voteFlag = !item.voteFlag
            voteChooseList = [item.id]
            return
          }
          item.voteFlag = false
        })
      } else {
        topicItem.voteList.forEach(item => {
          if(item.id === voteItem.id) {
            item.voteFlag = !item.voteFlag
            if(item.voteFlag) {
              voteChooseList.push(item.id)
            } else {
              voteChooseList.splice(voteChooseList.indexOf(item.id), 1)
            }
          }
        })
      }
      this.topicData[index].voteChooseList = voteChooseList
    },
    handlePublicVote(data) {
      if(data.voteChooseList.length > 0) {
        publishVote(data.voteChooseList).then(res => {
          data.voteFlag = true
          data.voteCount += 1
          data.voteList = res.voteList
        })
      } else {
        this.$toast.fail({
          message: '请选择后再投票！',
          position: 'top'
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.activity {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;

  &_content{
    flex: 1;
    overflow-y: auto;

    .topicItem {
      margin-top: 10px;
    }
  }
}
</style>
